<script>
import Layout from "./../../../layouts/main";
import appConfig from "../../../../app.config";

export default {
  page: {
    meta: [{ name: "Binance integration", content: appConfig.description }],
  },
  data() {
    return {
    };
  },
  name: "FAQs",
  components: {
    Layout
  },
};
</script>

<template>
  <Layout>
      <div class="row">
            <div class="col-lg-12">
                <!-- end card -->

                <div class="row justify-content-evenly">

                  Under development

                </div>
            </div><!--end col-->.
        </div><!--end row-->
    </Layout>
</template>
